import { template as template_11d17168c1034483bf3dc59a607092a3 } from "@ember/template-compiler";
const FKLabel = template_11d17168c1034483bf3dc59a607092a3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
